/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
} */
@import url("https://fonts.googleapis.com/css?family=Fira+Sans:400,500,600,700,800");

* {
  box-sizing: border-box;
}
body {
  min-height: 100vh;
  display: flex;
  font-weight: 400;
  font-family: "Fira Sans", sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6,
label,
span {
  font-weight: 500;
  font-family: "Fira Sans", sans-serif;
}

body,
html,
.App,
#root,
.auth-wrapper {
  width: 100%;
  height: 100%;
}

.navbar-light {
  background-color: #ffffff;
  box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
}

.auth-wrapper {
  background: linear-gradient(
    90deg,
    rgba(2, 0, 36, 1) 0%,
    rgba(9, 9, 121, 1) 32%,
    rgba(0, 212, 255, 1) 100%
  );
  /* background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab); */
  background-size: 400% 400%;
  animation: gradient 15s ease infinite;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: left;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.auth-inner {
  width: 450px;
  margin: auto;
  background: #ffffff;
  box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
  padding: 40px 55px 45px 55px;
  border-radius: 15px;
  transition: all 0.3s;
}

.auth-wrapper .form-control:focus {
  border-color: #167bff;
  box-shadow: none;
}

.auth-wrapper h3 {
  text-align: center;
  margin: 0;
  line-height: 1;
  padding-bottom: 20px;
}

.custom-control-label {
  font-weight: 400;
}

.forgot-password,
.forgot-password a {
  text-align: right;
  font-size: 13px;
  padding-top: 10px;
  color: #7f7d7d;
  margin: 0;
}

.forgot-password a {
  color: #167bff;
}

#components-layout-demo-top-side .logo {
  float: left;
  width: 120px;
  height: 31px;
  margin: 16px 24px 16px 0;
  background: rgba(255, 255, 255, 0.3);
}

.ant-row-rtl #components-layout-demo-top-side .logo {
  float: right;
  margin: 16px 0 16px 24px;
}

.site-layout-background {
  background: #fff;
}

.login-form-forgot {
  float: right;
}
.ant-col-rtl .login-form-forgot {
  float: left;
}
.login-form-button {
  width: 100%;
}

.logo {
  color: white;
  font-size: 25px;
}

@media (max-width: 768px) {
  .logo {
    font-size: 20px;
  }
}

.logoutBtn {
  position: absolute !important;
  right: 20px;
}

/* .ant-layout-header {
  padding: 0 25px !important;
} */

/* .ant-row-rtl .logo {
  float: right;
  margin: 16px 0 16px 24px;
} */

/* .site-layout-background {
  background: #fff;
} */
.iconPwd {
  position: relative;
}
.iconPwd .showPassword {
  position: absolute;
  right: 20px;
  color: #c2c2c2;
  top: 50%;
  transform: translateY(-50%);
}
.iconPwd .form-control {
  padding-right: 40px;
}
/* Upload */
.fileUpload {
  position: relative;
  overflow: hidden;
}
.fileUpload label {
  width: 100%;
  color: #0d6efd;
  background: rgba(0, 0, 0, 0.02);
  border: 1px dashed #d9d9d9;
  border-radius: 8px;
  cursor: pointer;
  transition: border-color 0.3s;
  display: block;
  text-align: center;
  padding: 22px 10px;
}
.fileUpload label:hover {
  border-color: #0d6efd;
}
.fileUpload label svg {
  font-size: 48px;
  margin-bottom: 16px;
}
.fileUpload label h2 {
  font-size: 16px;
  color: #000;
  font-weight: 500;
  margin-bottom: 0px;
}
.fileUpload input[type="file"] {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  cursor: pointer;
  z-index: 1;
}
/* .fileUpload input[type="file"]:valid + .fileUpload_box {
  width: 100%;
  text-align: center;
  position: absolute;
  top: 0;
  height: 100%;
  border-radius: 8px;
  padding: 22px 10px;
  border-color: #47cf73;
  background-color: #47cf73;
} */

.show-uploaded-file {
  position: relative;
  padding: 5px 10px;
  border: 1px dashed #d9d9d9;
  background-color: rgba(35, 111, 234, 0.4);
}

.show-uploaded-file img {
  /* margin: 5px; */
}

.show-uploaded-file h5 {
  font-size: 16px;
}

.show-uploaded-file button {
  position: absolute;
  top: 5px;
  right: 5px;
  font-weight: 800;
  border: none;
  background-color: rgba(0, 0, 0, 0);
}

.view-btn {
  width: 155px;
}

.table-responsive .react-bootstrap-table table {
  table-layout: inherit;
}

.table-responsive .dropdown-toggle::after {
  display: none;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.table tr:hover {
  background-color: #f5f5f5;
}

.a {
  color: var(--bs-link-color);
  text-decoration: none;
}
