.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.bs-linebreak {
  line-break: auto;
}

.authPage .authPage_left {
  background-image: url("../public/assets/images/login.svg");
  /* background-size: cover; */
  background-repeat: no-repeat;
  background-position: center center;
  height: 100%;
  position: fixed;
  width: 50%;
  top: 0;
  left: 0;
  border-right: inset;
}
.authPage .authPage_right_box {
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
}
